<template lang="pug">
	b-dropdown.dropdown.w-100.dropdown-plans.animated(:id="`${id}-dropdown-plans`" menu-class="p-0 m-0" :class="{'z-index-1032 ' : overlay}" :disabled='list.length == 0')
		template(#button-content)
			span.b3 {{ $t('promotions.plans') }}
			span.d-flex.dropdown-plans__length {{ list.length }}
		b-dropdown-item.my-4(v-for='(item, i) in list' :key='i')
			.d-flex.justify-content-between.align-items-center.mb-2
				span.overflow-wrap(v-html="item.title" :class="resources ? '' : 'mr-3'")
				.d-flex.align-items-center.text-nowrap(v-if="!resources")
					span.dropdown-plans__price(v-if="discount") {{ planPriceWithDiscount(item) }}
					span.dropdown-plans__price(v-if="!discount") {{ item.price.price() }}
					span.mt-2.ml-1.text-nowrap.dropdown-plans__currency(v-html="item.price.currencyTitle()")
					span.ml-2.dropdown-plans__price.text-nowrap.dropdown-plans__price--old(v-if="discount" v-html="`${item.price.priceWithCurrency()}`")
			.d-flex.justify-content-between.align-items-center.flex-wrap
				span.b4.dropdown-plans__discount(v-if="discount") -{{ discount }}%
				span.b4.dropdown-plans__date(v-if="item.period" :class="resources ? 'ml-0 mr-auto' : ''") {{ $t('plan.periods.' + item.period.title) }}
				.d-flex.align-items-center.text-nowrap(v-if="resources")
					span.dropdown-plans__price(v-if="discount") {{ Math.round(item.price.price() / 100 * (100 - discount)) }}
					span.dropdown-plans__price(v-if="!discount" :class="resources ? 'h3' : ''") {{ item.price.price() }}
					span.ml-1.text-nowrap.dropdown-plans__currency(v-html="item.price.currencyTitle()")
					span.ml-2.dropdown-plans__price.text-nowrap.dropdown-plans__price--old(v-if="discount" v-html="`${item.price.priceWithCurrency()}`")
</template>

<script>
export default {
	name: 'DropdownPlans',
	props: {
		list: {
			default: () => [],
			type: Array
		},
		id: {
			default: null,
			type: String
		},
		discount: {
			default: null,
			type: Number
		},
		resources: {
			default: false,
			type: Boolean
		}
	},
	data: () => ({
		overlay: false,
		windowInnerHeight: null,
		scrollbarWidth: 0
	}),
	created() {
		window.addEventListener('click', () => {
			if(this.overlay === true) {
				this.onClose();
			}
		}),
		this.windowInnerHeight = window.innerHeight;
		window.addEventListener('resize', () => {
			this.windowInnerHeight = window.innerHeight;
		})
	},
	mounted() {
		this.$root.$on('bv::dropdown::show', bvEvent => {
			if(bvEvent.componentId == `${this.id}-dropdown-plans`) {
				document.body.classList.add('overlay');
				this.overlay = true;
				if(!this.isMobile) {
					document.querySelector('body').style.paddingRight = this.scrollbarWidth + 'px';
				}
			}
		})
		this.$root.$on('bv::dropdown::hide', bvEvent => {
			if(bvEvent.componentId == `${this.id}-dropdown-plans`) {
				document.body.classList.remove('overlay');
				this.overlay = false;
				document.querySelector('body').style.paddingRight = 0;
			}
		}),
		this.getScrollbarWidth();
	},
	methods: {
		onClose() {
			document.body.classList.remove('overlay');
			this.overlay = false;
			document.querySelector('body').style.paddingRight = 0;
		},
		getScrollbarWidth() {
			// Creating invisible container
			const outer = document.createElement('div');
			outer.style.visibility = 'hidden';
			outer.style.overflow = 'scroll'; // forcing scrollbar to appear
			outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
			document.body.appendChild(outer);

			// Creating inner element and placing it in the container
			const inner = document.createElement('div');
			outer.appendChild(inner);

			// Calculating difference between container's full width and the child width
			this.scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);

			// Removing temporary elements from the DOM
			outer.parentNode.removeChild(outer);
		},
		planPriceWithDiscount(plan) {
			if (plan.price.isCurrencyRUB()) {
				return Math.round(plan.price.price() / 100 * (100 - this.discount));
			} else {
				return (plan.price.price() / 100 * (100 - this.discount)).toFixed(2).replace('.00', '');
			}
		},
	}
}
</script>
<style lang='scss'>
@import '~@/assets/css/sass/_mixins.scss';

.dropdown-plans {
	height: 50px;

	&.z-index-1032 {
		z-index: 1032;
	}

	&:hover,
	&:active,
	&:focus {
		button {
			background-color: var(--foreground-color) !important;
			color: var(--main-text-color) !important;
			box-shadow: var(--btn-box-shadow) !important;
		}

		.dropdown-plans__length {
			background-color: var(--bg-back);
		}
	}

	&__length {
		justify-content: center;
		min-width: 30px;
		padding: 1.5px 6px;
		font-size: 14px;
		line-height: 22px;
		color: var(--primary-text-color) !important;
		background-color: var(--foreground-color);
		border-radius: 6px !important;
		transition: var(--animation-time-short);
		margin-left: 10px;
	}

	&__price {
		font-size: 24px;
		line-height: 24px;

		&--old {
			font-size: 12px;
			line-height: 14px;
			color: var(--grey-text-color);
			text-decoration: line-through;
		}
	}

	&__currency {
		font-size: 14px;
		line-height: 24px;
		margin-top: 3px;
	}

	&__discount {
		padding: 3px 6px;
		color: var(--wr-text-color);
		background-color: var(--red-bg-color);
		border-radius: var(--border-radius-rounded);
	}

	&__date {
		padding: 3px 10px;
		color: var(--primary-text-color);
		background-color: var(--primary-bg-color);
		border-radius: var(--border-radius-rounded);
		margin-left: auto;
	}

	button {
		justify-content: flex-start;
		height: 50px;
		padding: 13px 15px !important;
		border: 1px solid transparent !important;
		background-color: var(--grayscale-bg-dark-back) !important;
		border-radius: var(--border-radius-rounded) !important;
		color: var(--main-text-color) !important;
		box-shadow: none !important;
	}

	.dropdown-toggle {
		border: 1px solid transparent !important;
		border-bottom: none !important;

		&::after {
			width: 18px;
			height: 18px;
			background: url('/assets/img/icons/arrow.svg');
			margin-left: auto !important;
			border: none !important;
		}
	}

	&.show .dropdown-toggle {
		position: relative;
		border-color: var(--border-line) !important;
		background-color: var(--foreground-color) !important;
		color: var(--main-text-color) !important;

		&::after {
			transform: rotate(180deg);
		}

		.dropdown-plans__length {
			background-color: var(--grayscale-bg-dark-back);
		}
	}

	&.show button {
		box-shadow: var(--box-shadow-hover) !important;
	}

	.dropdown-menu {
		top: 8px !important;
		right: 0 !important;
		padding: 0 15px !important;
		max-height: 360px !important;
		background-color: var(--foreground-color) !important;
		border-radius: var(--border-radius-rounded) !important;
		border-top-left-radius: 0 !important;
		border-top-right-radius: 0 !important;
		box-shadow: var(--box-shadow-plans) !important;
		border: 1px solid transparent !important;
		border-top: none !important;
		border-bottom: none !important;
	}

	&.show ul li:first-child:before {
		content: none !important;
	}

	.dropdown-item {
		display: flex;
		flex-direction: column;
		padding: 10px !important;
		color: var(--main-text-color) !important;
		box-shadow: var(--user-project-shadow) !important;
		border-radius: var(--border-radius-rounded) !important;
		background-color: var(--foreground-color);
	}

	&.animated {
		.dropdown-menu {
			overflow: auto;
			display: block!important;
			max-height: 0!important;
			//transition: max-height var(--animation-time-short) ease-in-out;

			&:not(.show) {
				padding: 0;
				border: none;
			}

			&.show {
				//transition: max-height var(--animation-time-short) ease-in-out;
				max-height: 360px!important;
				background-color: var(--foreground-color) !important;
				border: 1px solid var(--border-line) !important;
				border-top: none !important;
			}
		}
	}
}
</style>
