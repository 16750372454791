<template lang='pug'>
	b-modal(:id="id" ref='modal' centered content-class="modal-promotions" header-class="d-flex p-0 border-0 align-items-center" body-class="p-0 d-flex flex-column" hide-footer hide-header-close)
		template(#modal-header="{ close }")
			span.b1 {{ title }}
			icon-close.modal-promotions__close(@click="close()")
		span.b3.mb-3.overflow-wrap(v-html='text')
		.d-flex.justify-content-between
			.btn.btn-border.btn-small.w-100.mr-4(@click="close" v-if='showBtnClose') {{ $t('promotions.cancel') }}
			.btn.btn-small.w-100(v-if='item' @click='action' :class="[{'disabled': isPending || isLoading}, btnClass]")
				.loader(v-if='isLoading')
				span(v-else) {{ btnText }}
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {Plan} from '@/models/plan';
import {Promotion} from '@/models/promotion';
import {PromoCode} from '@/models/promoCode';
import {Resource} from '@/models/resource';
import IconClose from "@/components/Common/IconClose";

export default {
	name: 'ModalRemovePromotion',
	components: {
		IconClose
	},
	props: {
		id: {
			type: String,
			default: ''
		},
		title: {
			type: String,
			default: ''
		},
		text: {
			type: String,
			default: ''
		},
		btnText: {
			type: String,
			default: ''
		},
		btnClass: {
			type: String,
			default: 'btn-primary'
		},
		showBtnClose: {
			type: Boolean,
			default: true
		},
		item: {
			type: Object,
			default: () => (null)
		}
	},
	data: () => ({
		isLoading: false
	}),
	computed : {
		...mapGetters({
			isPending		: 'plans/isPending',
		})
	},
	methods : {
		...mapActions({
			rPlan 	: 'plans/remove',
			aPlan 	: 'plans/archive',

			rPromotion 	: 'project/removePromotion',
			aPromotion 	: 'project/archivePromotion',

			rPromocode 	: 'project/removePromocode',
			aPromocode 	: 'project/archivePromocode',

			rResource	: 'resources/remove',
		}),

		action () {
			if ( this.item.canBeDeleted ) return this.remove();

			return this.archive();
		},

		remove () {
			if ( this.item instanceof Plan ) return this.removePlan();
			if ( this.item instanceof Promotion ) return this.removePromotion();
			if ( this.item instanceof PromoCode ) return this.removePromocode();
			if ( this.item instanceof Resource ) return this.removeResource();
		},

		archive () {
			if ( this.item instanceof Plan ) return this.archivePlan();
			if ( this.item instanceof Promotion ) return this.archivePromotion();
			if ( this.item instanceof PromoCode ) return this.archivePromocode();
			if ( this.item instanceof Resource ) return this.removeResource();
		},

		removePlan () {
			this.isLoading = true;

			this.rPlan({id: this.item.id})
				.then ( () => {
					this.$notify("error", this.$t('success.planRemove'));
					this.$store.commit('plans/remove', this.item);
					this.$store.commit('project/removePlan', this.item);
					this.$store.commit('plans/edited', null);

					this.close();
					this.isLoading = false;
				})
				.catch (v => {
					this.$notify("error", this.$t('errors.planRemove'), v)
					this.isLoading = false;
				});
		},

		archivePlan () {
			this.isLoading = true;

			this.aPlan({id: this.item.id})
				.then ( () => {
					this.$notify("success", this.$t('success.planArchive'));
					this.$store.commit('plans/archive', this.item);
					this.$store.commit('project/editPlan', this.item);
					this.$store.commit('plans/edited', null);

					this.close();
					this.isLoading = false;
				})
				.catch (v => {
					this.$notify("error", this.$t('errors.planArchive'), v)
					this.isLoading = false;
				});
		},

		removePromotion () {
			this.isLoading = true;

			this.rPromotion({id: this.item.id})
				.then ( () => {
					this.close();
					this.$notify("error", this.$t('success.promotionRemove'));
					this.$store.commit('project/removePromotion', this.item);
					this.isLoading = false;
				})
				.catch (v => {
					this.$notify("error", v)
					this.isLoading = false;
				});
		},

		archivePromotion () {
			this.isLoading = true;

			this.aPromotion({id: this.item.id})
				.then ( () => {
					this.close();
					this.$notify("success", this.$t('success.promotionArchive'));
					this.$store.commit('project/archivePromotion', this.item);
					this.isLoading = false;
				})
				.catch (v => {
					this.$notify("error", v)
					this.isLoading = false;
				});
		},

		removePromocode () {
			this.isLoading = true;

			this.rPromocode({id: this.item.id})
				.then ( () => {
					this.close();
					this.$notify("error", this.$t('success.promocodeRemove'));
					this.$store.commit('project/removePromocode', this.item);
					this.isLoading = false;
				})
				.catch (v => {
					this.$notify("error", v)
					this.isLoading = false;
				});
		},

		archivePromocode () {
			this.isLoading = true;

			this.aPromocode({id: this.item.id})
				.then ( () => {
					this.close();
					this.$notify("success", this.$t('success.promocodeArchive'));
					this.$store.commit('project/archivePromocode', this.item);
					this.isLoading = false;
				})
				.catch (v => {
					this.$notify("error", v)
					this.isLoading = false;
				});
		},

		removeResource () {
			if ( !this.showBtnClose ) return this.close();
			this.isLoading = true;

			this.rResource({id: this.item.id, type: this.item.type})
				.then ( () => {
					this.$notify("error", `😢 &nbsp;&nbsp;&nbsp;${this.$t('success.resourceRemove')}`);
					this.$store.commit('project/removeResource', this.item);
					this.$store.commit('plans/removeResource', this.item);
					this.$store.commit('resources/remove', this.item);
					this.close();
					this.isLoading = false;
				})
				.catch (v => {
					this.$notify("error", `😢 &nbsp;&nbsp;&nbsp;${this.$t('errors.resourceRemove')}`)
					this.isLoading = false;
				});
		},

		close () {
			if ( this.$refs.modal )
				this.$refs.modal.hide();
		},
	},
	watch : {
		item : {
			handler (nV, oV) {
				if ( nV != null && nV.id ) {
					this.$refs.modal.show();
				}
			}
		}
	}
}
</script>

<style lang="scss">
@import '~@/assets/css/sass/_mixins.scss';

.modal-promotions {
	max-width: 360px;
	padding: 20px;
	margin: 0 auto;

	@include respond-below(sm) {
		border-bottom-left-radius: var(--border-radius-rounded) !important;
		border-bottom-right-radius: var(--border-radius-rounded) !important;
		margin: auto 12px;
	}

	&__close {
		transition: var(--animation-time-short);

		&:hover,
		&:active {
			opacity: 0.7;
		}
	}

	.modal-header {
		margin-bottom: 10px;
	}
}
</style>
